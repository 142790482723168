import { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { filterCaseStudiesBySection } from "../utils";

import BuildBreakdownSection from "../components/common/BuildServices/BuildBreakdownSection";

// Icons
import AWSLogoLarge from "../assets/svg/aws-logo_large.svg";
import AzureLogoLarge from "../assets/svg/azure_large.svg";
import GCPLogoLarge from "../assets/svg/google-cloud_large.svg";
import {
  UserGroupIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
  RocketLaunchIcon,
  PencilIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import BuildOfferings from "../components/sections/BuildOfferings";
import Carousel from "../components/sections/Carousel";

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.25,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const BuildServices = () => {
  const [selectedCaseStudies, setSelectedCaseStudies] = useState([]);
  const targetElementRef = useRef(null);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();

  const build = [
    {
      label: ["RAVL Build"],
      description:
        "A pod of RAVL engineers to deliver your toughest tech challenges.",
      icon: UserGroupIcon,
      works: [
        {
          label: "Deliver High Quality Solutions",
          description:
            "RAVL provides Pods of 6-12 experts who will own the technology challenge and deliver sustainable solutions for our clients.",
          icon: RocketLaunchIcon,
        },
      ],
      "Pod Breakdown": [
        { title: "Principal Engineer", num: 1 },
        { title: "Senior Engineers", num: 4 },
      ],
      "Enablement Team": [
        { title: "Platform Team", num: 2 },
        { title: "Executive Support", num: 2 },
      ],
    },
    {
      label: ["RAVL Build", "+"],
      description:
        "A mixed pod of RAVL's team and your team. Your employees are upskilled while your solution is built.",
      icon: WrenchIcon,
      works: [
        {
          label: "Deliver High Quality Solutions",
          description:
            "RAVL provides Pods of 6-12 experts who will own the technology challenge and deliver sustainable solutions for our clients.",
          icon: RocketLaunchIcon,
        },
        {
          label: "Ongoing And Relentless Upskilling",
          description:
            "While working on your solution, your future employees are constantly trained and mentored by RAVL experts for the term of the engagement so you gain new tech gurus.",
          icon: PencilIcon,
        },
      ],
      "Pod Breakdown": [
        { title: "Principal Engineer", num: 1 },
        { title: "Senior Engineers", num: 3 },
        { title: "Your Engineers", num: 5 },
      ],
      "Enablement Team": [
        { title: "Platform Team", num: 2 },
        { title: "Executive Support", num: 2 },
        { title: "RAVL Talent Team", num: 1 },
      ],
    },
    {
      label: ["RAVL Build", "X"],
      description:
        "Our BuildX offering delivers top-tier technology solutions and our clients have the option to hire a subset of the team to continue their journey.",
      icon: WrenchScrewdriverIcon,
      works: [
        {
          label: "Deliver High Quality Solutions",
          description:
            "RAVL provides Pods of 6-12 experts who will own the technology challenge and deliver sustainable solutions for our clients.",
          icon: RocketLaunchIcon,
        },
        {
          label: "Ongoing And Relentless Upskilling",
          description:
            "While working on your solution, your future employees are constantly trained and mentored by RAVL experts for the term of the engagement so you gain new tech gurus.",
          icon: PencilIcon,
        },
        {
          label: "Building your Talent Pipeline",
          description:
            "RAVL has relationships with top tech schools and bootcamps, allowing us to hire the best and brightest raw talent for you to keep.",
          icon: MagnifyingGlassCircleIcon,
        },
      ],
      "Pod Breakdown": [
        { title: "Principal Engineer", num: 1 },
        { title: "Senior Engineers", num: 3 },
        { title: "Your Future Engineers", num: 5 },
      ],
      "Enablement Team": [
        { title: "Platform Team", num: 2 },
        { title: "Executive Support", num: 2 },
        { title: "RAVL Talent Team", num: 2 },
      ],
    },
  ];

  const buildIcons = [
    { icon: AWSLogoLarge, alt: "AWS", link: "/partners/aws" },
    { icon: AzureLogoLarge, alt: "Azure", link: "/partners/microsoftazure" },
    { icon: GCPLogoLarge, alt: "Google Cloud Platform" },
  ];
  useEffect(() => {
    const buildCaseStudies = filterCaseStudiesBySection("build");
    setSelectedCaseStudies(buildCaseStudies);
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash === "#how-we-like-to-build") {
      setSelectedTab(2);
    } else {
      setSelectedTab(0);
    }
  }, [location.hash]);

  return (
    <main className="text-center flex flex-col gap-6 md:gap-20 px-6 py-6 md:px-12 md:py-12">
      <div className="relative flex justify-center items-center h-[500px]">
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className="flex flex-col gap-4 md:gap-8 mx-auto px-2.5 sm:px-0 text-center max-w-3xl"
        >
          <motion.h1
            variants={item}
            className="text-6xl font-bold leading-[1.15] text-black sm:leading-[1.15]"
          >
            <span className="text-purple-700">RAVL</span> Build
          </motion.h1>
          <motion.p variants={item} className="text-gray-600 sm:text-xl">
            Our Software Engineering, Cloud, AI & Data Solutions, and DevSecOps
            Enablement capabilities can address your challenges, propel your
            business forward, and leave you with enabled talent.
          </motion.p>
          <motion.div variants={item} className="flex flex-wrap justify-center">
            <a
              href="#What-we-build"
              className="btn-primary flex items-center justify-center shadow-md text-base w-3/4 md:w-[45%] gap-4"
            >
              Explore Below <ArrowDownIcon className="h-4" />
            </a>
          </motion.div>
        </motion.div>
      </div>

      {/* Offerings Section */}
      <BuildOfferings />

      {/* Advisory Section */}
      <div className="flex flex-col items-center gap-8">
        <p className="text-2xl">
          Strategize before building. Explore how RAVL Advisory can help.
        </p>
        <Link
          to={"/services/advisory"}
          className="btn-primary flex items-center justify-center shadow-md text-base w-[15rem] md:w-[20rem]"
        >
          Explore Advisory
        </Link>
      </div>

      <div id="how-we-like-to-build"></div>

      {/* Build Breakdown Section */}
      <BuildBreakdownSection
        build={build}
        selectedTab={selectedTab} // Pass the selected tab index
        onTabChange={(index) => setSelectedTab(index)} // Update state on tab change
        targetElementRef={targetElementRef}
      />

      {/* Build Section */}
      <section className="flex flex-col gap-8 xl:px-32">
        <h2 className="text-4xl font-bold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15]">
          <span className="text-purple-700">Where</span> we like to{" "}
          <span className="text-purple-700">Build</span>
        </h2>
        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center gap-4">
          {buildIcons.map((icon) => {
            const iconElement = (
              <img
                key={uuidv4()}
                src={icon.icon}
                alt={icon.alt}
                className="w-24 h-24 sm:w-auto sm:h-auto mx-4"
              />
            );
            return icon.link ? (
              <Link to={icon.link} key={uuidv4()}>
                {iconElement}
              </Link>
            ) : (
              iconElement
            );
          })}
        </div>
      </section>

      {/* Examples Section */}
      <section className="flex flex-col gap-8 xl:px-32">
        <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
          <span className="text-purple-700">Examples</span> of our{" "}
          <span className="text-purple-700">Build</span> work
        </h2>
        <Carousel caseStudies={selectedCaseStudies} />
      </section>
    </main>
  );
};

export default BuildServices;
