import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import AppLayout from "./components/layout/AppLayout";
import BackgroundBlur from "./components/common/BackgroundBlur";
import ScrollToTop from "./components/common/ScrollToTop";

//  Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import BuildServices from "./pages/BuildServices";
import AdvisoryServices from "./pages/AdvisoryServices";
import AWS from "./pages/AWS";
import Azure from "./pages/Azure";
import CaseStudy from "./pages/CaseStudy";
import ErrorPage from "./pages/ErrorPage";

function App() {
  return (
    <div className="min-h-screen">
      <BackgroundBlur />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/services/build" element={<BuildServices />} />
            <Route path="/services/advisory" element={<AdvisoryServices />} />
            <Route path="/partners/aws" element={<AWS />} />
            <Route path="/partners/microsoftazure" element={<Azure />} />
            <Route path="/our-work/:caseStudyName" element={<CaseStudy />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
